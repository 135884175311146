import { template as template_8e14566c72274f48b7557ffc3ebef108 } from "@ember/template-compiler";
const WelcomeHeader = template_8e14566c72274f48b7557ffc3ebef108(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
