import { template as template_e9bd9e0c977e4b7b8ed9063f50d43930 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_e9bd9e0c977e4b7b8ed9063f50d43930(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
