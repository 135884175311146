import { template as template_003902338144441e8c24b3982e820ee0 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_003902338144441e8c24b3982e820ee0(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
