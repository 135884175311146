import { template as template_f29edd6cd7e6405a8ba003705ce958a7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_f29edd6cd7e6405a8ba003705ce958a7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
